import React, { useState } from "react"

//Redux
import { useSelector } from "react-redux"

//Components
import Section from "../../components/Tools/Section/Section"
import FeatureContainer from "../../components/Tools/FeatureContainer/FeatureContainer"
import CommunicationBar from "../../components/Tools/CommunicationBar/CommunicationBar"

//Screenshots
import ReimbursementsSS from "../../assets/company/muni-app-reimbursements.svg"

// Icons
import depositIcon from "../../assets/company/deposit-icon.svg"
import matchExpensesIcon from "../../assets/company/match-expenses-icon.svg"

// Local Data
import reimbursementsData from "../../localized/reimbursementsLocalized.json"
import communicationBarData from "../../localized/communicationBarLocalized.json"
import Layout from "../../components/Layout"

const Reimbursements = () => {
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  const [signUpEmail, setSignUpEmail] = useState("")

  const reimbursementsFeaturesData = [
    {
      cardIcon: depositIcon,
      cardTitle: reimbursementsData.oneClick?.[currentLanguage],
      cardDescription: reimbursementsData.depositEasily?.[currentLanguage],
    },
    {
      cardIcon: matchExpensesIcon,
      cardTitle: reimbursementsData.closeBooks?.[currentLanguage],
      cardDescription: reimbursementsData.expenseMatching?.[currentLanguage],
    },
  ]

  // useEffect(() => {
  //   setCurrentLanguage(localStorage.getItem('language'))
  // },[currentLanguage])

  const reimbursementsFirstSection = (
    <div className="media_section" id="reimbursements-first-section">
      <div
        className="wow animate__fadeIn media_section_content"
        id="reimbursements-media-container"
        data-wow-duration="2s"
      >
        <img
          src={ReimbursementsSS}
          alt="Home mockup"
          className="media_section_content_mobile beige_media_shadow"
        />
      </div>
      <div className="section_paragraph">
        <span
          data-wow-duration="2s"
          className="wow animate__fadeInUp section_paragraph_top"
          id="third-section-title"
        >
          {reimbursementsData.seamlessFlow?.[currentLanguage]}
        </span>
        <span
          className="wow animate__fadeInLeft section_paragraph_middle"
          data-wow-duration="1s"
          id="third-section-subtitle"
        >
          {reimbursementsData.reimburseEmployees?.[currentLanguage]}
        </span>
        <div
          className="wow animate__fadeInUp section_paragraph_bottom"
          data-wow-duration="1.5s"
        >
          <span>
            {reimbursementsData.fullyIntegrated?.[currentLanguage]} <br />
            {reimbursementsData.inNoTime?.[currentLanguage]}
          </span>
        </div>
      </div>
    </div>
  )

  const reimbursementsSecondSection = (
    <div id="reimbursements-second-section">
      <FeatureContainer data={reimbursementsFeaturesData} />
    </div>
  )
  const reimbursementsThirdSection = (
    <div id="reimbursements-third-section">
      <div
        className="communication_bar_container wow animate__fadeIn"
        data-wow-duration="2s"
      >
        <div className="communication_bar_header">
          {`${communicationBarData.beTheFirst?.[currentLanguage]} !`}
        </div>
        <CommunicationBar
          id="reimbursements-signup-bar"
          inputValue={signUpEmail}
          changeInputValue={setSignUpEmail}
          navigateLink={`/waitlist/?lang=${currentLanguage}`}
          type="joinWaitlist"
          white={true}
        />
      </div>
    </div>
  )

  return (
    <Layout>
      <div id="reimbursements">
        <Section
          backgroundColor="black"
          sectionContent={reimbursementsFirstSection}
          sectionId="reimbursements-first-section-wrapper"
        />
        <Section
          backgroundColor="gray"
          sectionContent={reimbursementsSecondSection}
          sectionId="reimbursements-second-section-wrapper"
          otherClassName="px-5"
        />
        <Section
          backgroundColor="gray"
          sectionContent={reimbursementsThirdSection}
          sectionId="reimbursements-third-section-wrapper"
          otherClassName="pt-0"
        />
      </div>
    </Layout>
  )
}

export default Reimbursements
